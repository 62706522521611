import React from 'react';

const contractAddress = process.env.REACT_APP_SC_ADDRESS;
const contractChainExplorer = process.env.REACT_APP_SC_CHAIN_EXPLORER;
const version = process.env.REACT_APP_VERSION;

export function Footer() {

  return (<>

    <div className="b-divider"></div>

    <div className="bg-dark text-secondary px-4 text-center">
      <div className="py-4">
        <span className="sc">Smart Contract : <a href={`https://${contractChainExplorer}/address/${contractAddress}`} target="_blank" rel="noreferrer">{contractAddress}</a></span><br />
        <span className='made-with-love'>DApp v.{version} made with <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="currentColor" className="bi bi-heart-fill" viewBox="0 0 16 16"><path fillRule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"></path></svg> by <a href="https://profile.rpgmax.fr" target="_blank" rel="noreferrer">RpGmAx</a></span>
      </div>
    </div>

    <div className="b-divider"></div>

    </>)
}
