import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import './styles/App.css';

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet } from 'wagmi/chains';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { Home } from './Home';
import { Mint } from './Mint';

const { chains, publicClient } = configureChains(
  [mainnet],
  [
    alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Aristo Special Edition',
  projectId: '5a0ae6beb4a2a4f2c76076ff6a5a1baa',
  chains
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

function App() {
  return (
    <WagmiConfig config={wagmiConfig}>
      <RainbowKitProvider chains={chains}>
        <Header />
        <Router>
          <Routes>
            <Route exact path="/:tokenId" element={<Mint />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>
        <Footer />
    </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
