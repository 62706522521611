import * as React from 'react'

import { useParams } from 'react-router-dom';
import { useEffect, useState, useCallback } from 'react';

import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';

import {
  useContractRead,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
} from 'wagmi'
import { useDebounce } from 'usehooks-ts'

import abi from './contracts/ABI.json';
import sc from './contracts/sc_lists.json';

import { useAccount } from 'wagmi'

// ALCHEMY
const { Network, Alchemy } = require('alchemy-sdk');

const settings = {
  apiKey: process.env.REACT_APP_ALCHEMY_API_KEY,
  network: Network.ETH_MAINNET,
};

const alchemy = new Alchemy(settings);

// Contrôle
const isValidTokenID = (tokenId) => {
  if (tokenId.trim() === '') { return false; }
  return !isNaN(tokenId);
}

// Messages d'erreur
const friendlyErrorMessage = (errorMsg) => {
  const errorMappings = {
    "Oh no, token already used :/": "Token déjà utilisé pour ce mint",
    "Hey, you aren't holder :/": "Ce token ne vous appartient pas",
    "Oh no, supply overrun :/": "Plus de supply pour ce special edition",
    "Pausable: paused": "Mint en pause",
  };
  
  return errorMappings[errorMsg] || errorMsg;
};

export function Mint() {

  const [genesisTokenID, setGenesisTokenID] = React.useState('')
  const { tokenId } = useParams()

  const debouncedGenesisTokenID = useDebounce(genesisTokenID)
  const { address, isConnected } = useAccount()
  const [scList, setScList] = useState([])
  const [userNFTS, setUserNFTS] = useState([])
  const [userNFTSLoaded, setUserNFTSLoaded] = useState(false)

  // Lecture SC
  const { data: nextTokenID, isSuccess: nextTokenIDIsSuccess } = useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'nextTokenID',
    account: address
  })

  const { data: iscData, isSuccess: iscIsSuccess } = useContractRead({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'getISCAddresses',
    args: [parseInt(tokenId)],
    enabled: Boolean(isValidTokenID(tokenId)),
    account: address
  })

  useEffect(() => {
    if (iscData) {
      const newScList = iscData.map((address) => {
        const name = sc[address];
        return name
          ? { address: address, name: name }
          : { address: address, name: "Collection externe" };
      });
      setScList(newScList);
      getUserNFTS();
    }
  }, [iscData]);

  // Lecture NFTs utilisateur
  const getUserNFTS = useCallback(async () => {
    if (isConnected) {
      setUserNFTSLoaded(false);
      try {
        const nfts = await alchemy.nft.getNftsForOwner(address, {
          contractAddresses: iscData,
      })
        //console.log(nfts.ownedNfts);
        setUserNFTS(nfts.ownedNfts);
        setUserNFTSLoaded(true);
      } catch(error) {
        console.error('Error during getUserNFTS fx', error)
      }
    }
  }, [isConnected, address]);

  // Listbox des NFTs de l'utilisateur
  const userNFTSListbox = (userNFTS, iscData) => {
    // Bascule en lowercase
    const iscDataLower = iscData.map(address => address.toLowerCase());
    // Mapping addresses > noms
    const scMap = scList.reduce((map, sc) => ({ ...map, [sc.address.toLowerCase()]: sc.name }), {});
    // Filtrage pour retirer l'ensemble des NFTs non concernés
    const filteredNFTs = userNFTS.filter(nft => iscDataLower.includes(nft.contract.address.toLowerCase()));

    // Regroupement des IDs identiques
    const groupedNFTs = filteredNFTs.reduce((grouped, nft) => {
      const mappedName = scMap[nft.contract.address.toLowerCase()] || nft.contract.name;
      const nftGroup = grouped[nft.tokenId] || { tokenId: nft.tokenId, names: [] };
      nftGroup.names.push(mappedName);
      grouped[nft.tokenId] = nftGroup;
      return grouped;
    }, {});

    const listBoxItems = Object.values(groupedNFTs).map((nft, index) => (
      <option key={index} value={nft.tokenId}>{nft.names.join(' ou ')} #{nft.tokenId}</option>
    ));

    return (
        <Form.Select className="mb-3" onChange={(e) => setGenesisTokenID(e.target.value)} value={genesisTokenID}>
        {userNFTSLoaded === false ? (
          <option value="">Chargement en cours</option>
        ) : listBoxItems.length >= 1 ? (
          <>
            <option value="">Veuillez sélectionner un NFT éligible</option>
            {listBoxItems}
          </>
        ) : (
          <option>Aucun NFT éligible sur ce wallet</option>
        )}
        </Form.Select>
      );
  };

  // Écriture SC
  const {
    config,
    error: prepareError,
    isError: isPrepareError,
    isSuccess: isPrepareSuccess,
  } = usePrepareContractWrite({
    address: process.env.REACT_APP_SC_ADDRESS,
    abi: abi,
    functionName: 'mint',
    args: [parseInt(tokenId), debouncedGenesisTokenID, '0x'],
    enabled: Boolean(debouncedGenesisTokenID),
  })

  const { data, write } = useContractWrite(config)

  const { isLoading, isSuccess } = useWaitForTransaction({ hash: data?.hash })

  useEffect(() => {
    setGenesisTokenID('');
  }, [address, isSuccess]);

  useEffect(() => {
    getUserNFTS();
  }, [isSuccess]);

  return (<>

    {isConnected && (<>

      {iscData ? (<>

      {(isValidTokenID(tokenId) && tokenId < Number(nextTokenID)) ? (<>

        <Container className="col-xl-10 col-xxl-8 px-4">
          <Row className="py-2">
            <Col lg={7} className="text-center text-lg-start mt-5">
              <h1 className="display-4 fw-bold lh-1 mb-4">Special Edition #{tokenId}</h1>

              <div className="col-lg-10 fs-4 text-white">
              {iscData.length >= 1 ? (<>
                  Liste des collections éligibles pour ce mint :
                  <ul> {iscIsSuccess && scList.map((item, index) => ( <li key={index}><a href={`https://${process.env.REACT_APP_SC_CHAIN_EXPLORER}/address/${item.address}`} target="_blank" rel="noreferrer">{item.name}</a></li> ))} </ul>
                  </>) : (<>Cette collection ne dispose d'aucune collection liée.<br />Son mint est lié à un snapshot.</>) }
              </div>

            </Col>
            <Col md={10} lg={5} className="mx-auto">
              <Form className="p-4 p-md-5 border rounded-3 bg-light" onSubmit={(e) => {
                e.preventDefault()
                write?.()
              }}>
                {userNFTSListbox(userNFTS, iscData)}
                  <Button type="submit" className={`w-100 btn-lg ${isSuccess && !Boolean(genesisTokenID) ? 'btn-success' : (isPrepareError ? 'btn-danger' : 'btn-primary')}`} disabled={isLoading || !(isPrepareSuccess && Boolean(genesisTokenID))}>
                    {isLoading ? 'Mint en cours...' : (isSuccess && !Boolean(genesisTokenID) ? 'Mint réussi' : (isPrepareError && prepareError.cause && prepareError.cause.reason ? `${friendlyErrorMessage(prepareError.cause.reason)}` : 'Mint'))}
                  </Button>
                <hr className="my-4" />
                <small className="text-muted">Sélectionnez simplement un token d'une collection éligible dans la liste ci-dessus puis cliquez sur "Mint" !</small>
              </Form>
            </Col>
          </Row>
        </Container>

        </>): (<><Alert className="alert alert-danger m-4">Erreur : ID de Special Edition invalide, veuillez vérifier votre lien.</Alert></>) }

      </>): (<><Alert className="alert alert-danger m-4">Erreur : ID de Spêcial Edition invalide, veuillez vérifier votre lien.</Alert></>) }

    </>)}

  </>)
}
