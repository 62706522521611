import { Container, Row, Col } from 'react-bootstrap';

export function Home() {

  return (<>
    <Container className="col-xl-10 col-xxl-8 px-4 py-5">
      <Row className="align-items-center g-lg-5 py-5">
        <Col lg={12} className="text-center">
          <p className="fs-4">Hey ! Tu es bien sur la DApp des Aristocrats Special Edition mais il te manque l'ID du NFT souhaité.<br />Rendez-vous sur notre <a href="https://discord.gg/KhfvV3BHRK" target="_blank" className="text-secondary">Discord</a> :)</p>
        </Col>
      </Row>
    </Container>  
  </>)
}
